#home {
  position: relative;
  // background: url("../../assets/bgIMG.png");

  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 1920px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  margin-top: -6rem;

  @media screen and (max-width: 1280px) {
    width: 100%;
    margin-top: 0rem;
    margin-right: 0rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      align-self: center;
    }
  }
}

.box__container {
  position: relative;
}
.app__header-badge {
  .logo__container {
    width: 6rem;
    margin: 0;
  }

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    //background: #edf2f874;
    border-radius: 15px;
    flex-direction: row;
    width: fit-content;

    //box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    img {
      width: 6rem;
    }
  }
  div {
    align-self: center;
    justify-self: center;
    h1 {
      font-size: 38px;
      @media screen and (max-width: 1480px) {
        font-size: 1.8rem;
      }
      @media screen and (max-width: 1280px) {
        font-size: 38px;
      }
    }
  }
  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;
    align-items: center;

    p {
      width: 100%;
      text-transform: capitalize;
      text-align: left;
    }
  }

  span {
    font-size: 3.5rem;

    @media screen and (min-width: 1920px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1280px) {
    justify-content: flex-start;
    align-items: flex-start;
    div {
      align-self: center;
      justify-self: center;
    }
  }
}
.header__text_p {
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  color: var(--gray-color);
  margin-bottom: 3rem;
  // text-align: justify;

  @media screen and (min-width: 1920px) {
    font-size: 1.25rem;
  }
}
.job_text {
  color: var(--gray-color);
  text-align: center;
}
.job_text2 {
  color: rgba(95, 95, 95, 0.621);
  text-align: center;
}

.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;
  z-index: 10;
  margin-bottom: 4rem;
  
  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: 10;

    background: var(--white-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    img {
      width: 60%;
      height: 60%;
      
    }
  }

  // circles with different height & width

  div:nth-child(1) {
    width: 100px;
    height: 100px;
    z-index: 10;
  }

  div:nth-child(2) {
    margin: 1.75rem;
    width: 150px;
    height: 150px;
    z-index: 10;
  }

  div:nth-child(3) {
    width: 70px;
    height: 70px;
    z-index: 10;
  }

  @media screen and (min-width: 1920px) {
    div:nth-child(2) {
      width: 400px;
      height: 400px;
      z-index: 10;
    }

    div:nth-child(3) {
      width: 170px;
      height: 170px;
      z-index: 10;
    }

    div:nth-child(1) {
      width: 200px;
      height: 200px;
      z-index: 10;
    }
  }

  @media screen and (max-width: 1280px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
    z-index: 10;

    div {
      margin: 1rem;
      z-index: 10;
    }
  }
}
.image-container {
  width: 300px;
  height: 300px;
  background-size: cover;
  background-position: center;
  margin: 20px;
  box-shadow: 1 15px 15px 15px rgba(142, 0, 142, 0.5);
  animation: imageAnimate 5s ease-in-out infinite;
  transition: all 1s ease-in-out;
}

.app__header-img {
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

  img {
    width: 100%;
    object-fit: contain;
    z-index: 1;
    border: 1rem;
    filter: drop-shadow(1px  1px  15px rgba(17, 10, 10, 0.753));
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    width: 20rem;
    height: 20rem;
    animation: imageAnimate 5s ease-in-out infinite;
    transition: all 3s ease-in-out;

    @media screen and (max-width: 480px) {
      margin-bottom: 3rem;
      margin-left: 0.6rem;
    }

    @media screen and (min-width: 480px) {
      width: 22rem;
      height: 22rem;
    }
    @media screen and (min-width: 620px) {
      width: 25rem;
      height: 25rem;
    }
    @media screen and (min-width: 720px) {
      width: 28rem;
      height: 28rem;
    }
    @media screen and (min-width: 960px) {
      width: 30rem;
      height: 30rem;
    }
    @media screen and (min-width: 1100px) {
      width: 22rem;
      height: 22rem;
    }
    @media screen and (min-width: 1280px) {
      width: 22rem;
      height: 22rem;
    }
    @media screen and (min-width: 1500px) {
      width: 30rem;
      height: 30rem;
    }
    @media screen and (min-width: 1920px) {
      width: 38rem;
      height: 38rem;
    }
  }

  @keyframes imageAnimate {
    0%,
    100% {
      border-radius: 30% 70% 70% 30% / 30% 52% 48% 70%;
      box-shadow: 0 -2vmin 4vmin #6f9cc9 inset, 0 -4vmin 4vmin #6b7688 inset,
        0 -2vmin 7vmin #ffffffa0 inset;
    }

    10% {
      border-radius: 50% 50% 20% 80% / 25% 80% 20% 75%;
    }

    20% {
      border-radius: 67% 33% 47% 53% / 37% 20% 80% 63%;
    }

    30% {
      border-radius: 39% 61% 47% 53% / 37% 40% 60% 63%;
    }

    40% {
      border-radius: 39% 61% 82% 18% / 74% 40% 60% 26%;
    }

    50% {
      border-radius: 100%;
    }

    60% {
      border-radius: 50% 50% 53% 47% / 72% 69% 31% 28%;
    }

    70% {
      border-radius: 50% 50% 53% 47% / 26% 22% 78% 74%;
    }

    80% {
      border-radius: 50% 50% 53% 47% / 26% 69% 31% 74%;
    }

    90% {
      border-radius: 20% 80% 20% 80% / 20% 80% 20% 80%;
    }
  }

  .blur__effect {
    display: flex;
    position: absolute;
    width: 100%;
    height: 1rem;
    margin-bottom: -10px;
    backdrop-filter: blur(3px);
    z-index: 5;
  }
  
}

.box__container .shadow__1 {
  box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.1),
    10px 10px 10px rgb(0, 0, 0, 0.1),
    inset -10px -10px 10px rgb(255, 255, 255, 0.2),
    inset 10px 10px 15px rgb(0, 0, 0, 0.1);
}
.box__container .shadow__2 {
  box-shadow: inset 4px 4px 5px rgb(255, 255, 255, 0.2),
    inset -4px -4px 5px rgb(0, 0, 0, 0.1), 10px 40px 40px rgb(0, 0, 0, 0.1);
}
