.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__skills-container {
  width: 80%;
  margin-top: 3rem;

  display: flex;
  flex-direction: column;
 

  @media screen and (max-width: 1920px) {
    width: 80%;
    flex-direction: column;
  }
}

.app__skills-listContainer{
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
  
}


.app__skills-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  
  

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
}

.app__skills-item {
  flex-direction: column;
  text-align: center;

  margin: 1rem;

  transition: all 0.3s ease-in-out;

  div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #fef4f5;

    img {
      width: 50%;
      height: 50%;
    }
&:hover::before,
&:hover::after {
  content: "";
  position: absolute;
  inset: 0.0rem;
  z-index: -1000000;
  
  border-radius: 50%;
  background: radial-gradient(
    circle closest-side,
    var(--clr-6),
    var(--clr-7),
    var(--clr-8),
    var(--clr-9),
    var(--clr-10)
  );
  background-position: center;
  background-repeat: repeat;
  animation: scalePulse 2.5s linear infinite;
}

.hovered::after {
  filter: blur(1.5rem);
  z-index: -100000;
}

@keyframes scalePulse {
  0%, 100% {
    background-size: 90%;
  }
  25% {
    background-size: 100%;
  }
  50% {
    background-size: 90%;
  }
  75% {
    background-size: 100%;
  }
}

    @media screen and (min-width: 1920px) {
      width: 150px;
      height: 150px;
    }

    @media screen and (max-width: 450px) {
      width: 70px;
      height: 70px;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
    transition: transform 0.3s ease, font-weight 0.3s ease;
  }

  &:hover{
    p {
      font-weight: 500;
      transform: scale(1.2);
    }
  }

  @media screen and (min-width: 1920px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }
}

.app__skills-exp {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
h2{
  font-weight: 800;
  color: var(--gray-color);
  margin-bottom: 5px;
  margin-left: 25px;
  align-items: end;
}
  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
}

.app__skills-exp-item {
  border: 3px solid white;
  border-radius: 10px;
  padding-inline: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
  @media screen and (max-width: 680px) {
    border: none;
    
  }
}

.app__skills-exp-works {
  flex: 1;
  width: 100%;
  

  .app__skills-exp-work {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
    cursor: pointer;

    h4 {
      font-weight: 500;
    }

    p {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
      align-items: end;
    }
    @media screen and (max-width: 680px) {
      h4 {
        font-weight: 200;
      }
      p {
        font-weight: 100;
        color: var(--gray-color);
        margin-top: 5px;
        align-items: end;
      }
    }
  }
}
.hidden {
  display: none;
}
.desc__container {
  width: 80%;
  align-self: center;
  justify-content: right;
  margin-bottom: 2rem;
  border-radius: 5px;
  background-color: #ffffffa0;
  padding: 1rem;
}
.card__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 450px) {
    justify-content: center;
    flex-direction: column;
  }
  @media screen and (max-width: 1280px) {
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  @media screen and (min-width: 1920px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

.header__skills{
color: #264148bf;
}



.card__container_card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  background-color: #edf2f8;
  align-items: flex-start;
  justify-content: center;
  border-radius: 1rem;
  margin: 2rem;
  padding: 1rem;
  &:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }
  h2 {
    text-align: center;
    width: 100%;
    
    font-size: 2.5rem;
    
    @media screen and (min-width: 1920px) {
      font-size: 3rem;
    }
  }
  
  div {
    font-size: 1rem;
    @media screen and (min-width: 1920px) {
      font-size: 1.8rem;
    }
  }
  
  @media screen and (max-width: 1280px) {
    width: 100%;
    
    align-self: center;
  }
  
  @media screen and (max-width: 920px) {
    width: 100%;
    padding: 0rem;
    margin-top: 1.2rem;
    text-size-adjust: 0.5rem;
  }
  
  @media screen and (min-width: 1920px) {
    width: 100%;
    
    margin-top: 1.2rem;
    margin: 1rem;
  }
}

.skill__row{
  display: flex; /* Use flexbox */
  flex-direction: row; /* Arrange items in a row */
  align-items: center; /* Center items vertically */
  //justify-content: flex-start; /* Align items to the start horizontally */
  width: auto;
 /* Ensures padding and border are included in the width */
  padding: 0.5rem; /* Adjust as needed */
   /* Adjust as needed */
  img{
    width: auto;
    max-width: 40px;
   height: auto;
   margin-right:0.5rem ;
  }

  p{
    margin: 0;
  }

  &:hover{
    p{
      font-weight: bold;
    }
  }

  @media screen and (max-width: 720px) {
    width: 90%;
    margin: 0rem;
    padding: 0.3rem;
    justify-content: center;
    align-items: center;
    
  }
}
.skill__row2{
  display: flex; /* Use flexbox */
  flex-direction: row; /* Arrange items in a row */
  align-items: center; /* Center items vertically */
  flex-wrap: wrap;
  //justify-content: flex-start; /* Align items to the start horizontally */
  width: auto;
 /* Ensures padding and border are included in the width */
  padding: 0.9rem; /* Adjust as needed */
   /* Adjust as needed */
  img{
    width: 30px;
   height: auto;
   margin-right:0.5rem ;
  }

  p{
    margin: 0.2rem;
  }

  
  @media screen and (max-width: 720px) {
    width: 100%;
    
    flex-direction: column;;
  }
  
}

.card__container_item {
  background-color: #f9f9f985;
  justify-content: center;
  margin: 0.25rem;
  padding: 0.18rem;
  padding-inline: 0.48rem;
  border-radius: 5px;
  @media screen and (max-width: 720px) {
    flex-direction: row;
    margin-inline-start: 0.2rem;
  padding: 0rem;
  padding-inline: 0.5rem;
    p{
      justify-content: center;
      text-align: center;
      font-size: x-small;
    }
  }
  
}

.bg__arrow {
  background-color: #cff2fb;
  border-radius: 50%;
}

.container__arrow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 10%;
  }
}

.app__skills-exp-year {
  margin-right: 3rem;

  p {
    font-weight: 800;
    color: var(--secondary-color);
  }

  @media screen and (max-width: 450px) {
    margin-right: 1rem;
  }
}

.skills-tooltip {
  max-width: 300px !important;
  background-color: var(--white-color) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  color: var(--gray-color) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  opacity: 1 !important;

  @media screen and (min-width: 1920px) {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  }
}
.shadow__1 {
  box-shadow: -8px -8px 15px  rgb(0, 0, 0, 0.05),
    8px -8px 15px rgb(0, 0, 0, 0.05),
    -8px 8px 15px rgb(0, 0, 0, 0.05),
    8px 8px 15px rgb(0, 0, 0, 0.05),
   inset -12px -12px 25px rgb(255, 255, 255, 0.2),
   inset  12px -12px 25px rgb(255, 255, 255, 0.2),
   inset -12px 12px 25px rgb(255, 255, 255, 0.2),
  inset  12px 12px 25px rgb(255, 255, 255, 0.2),
}
.shadow__2 {
  box-shadow: inset 4px 4px 5px rgb(255, 255, 255, 0.2),
    inset -4px -4px 5px rgb(0, 0, 0, 0.1);
}
