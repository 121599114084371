@property --gradient-angle{
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

@keyframes movingText {
  0% {
    transform: translateX(-20%);
  }
  14% {
    transform: translateX(0);
  }
}
@keyframes movingText2 {
  0% {
    transform: translateX(20%);
  }
  14% {
    transform: translateX(0);
  }
}

.text__scroll_left {
  left: 0;
  animation: movingText linear;
  animation-timeline: scroll();
  @media screen and (max-width: 720px) {
    animation: none;
  }
}
.text__scroll_right {
  right: 0;
  animation: movingText2 linear;
  animation-timeline: scroll();
  @media screen and (max-width: 980px) {
    animation: none;
  }
}


.black__span {
  color: black !important;
}

.app__profile-item {
  width: 260px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 240px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 1280px) {
    width: 270px;
    margin: 2rem 4rem;

    img {
      height: 260px;
    }
  }

  @media screen and (min-width: 1920px) {
    width: 400px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}
.box__container .shadow__1 {

  box-shadow: 
    15px 15px 15px rgba(0, 0, 0, 0.211);
}
.box__container .shadow__2 {
  box-shadow: inset 4px 4px 5px rgb(255, 255, 255, 0.2),
    inset -4px -4px 5px rgb(0, 0, 0, 0.1), 10px 40px 40px rgb(0, 0, 0, 0.1);
}


.hovered {
  position: relative;
}

.hovered::before,
.hovered::after {
  content: "";
  position: absolute;
  inset: 0.35rem;
  z-index: -1;
  border-radius: 5px;
  background: linear-gradient(
    var(--gradient-angle),
    var(--clr-1),
    var(--clr-2),
    var(--clr-3),
    var(--clr-4),
    var(--clr-5)
  );
  animation: rotation 5.1s linear infinite;
}

.hovered::after {
  filter: blur(1.5rem);
  z-index: -1;
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  12%{
    --gradient-angle: 45deg;
  }
  37%{
    --gradient-angle: 135deg;
  }
  
  100% {
    --gradient-angle: 360deg;
  }
}