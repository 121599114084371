.app__navbar {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 2rem;
  background: rgba(167, 224, 225, 0.204);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 2;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 180px;
    height: auto;

    @media screen and (min-width: 1920px) {
      width: 280px;
      height: auto;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  list-style: none;

  li {
    margin: 0 1rem;
    cursor: pointer;

    flex-direction: column;

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;

      margin-bottom: 5px;
    }

    a {
      color: var(--gray-color);
      text-decoration: none;
      flex-direction: row;

      text-transform: uppercase;
      font-weight: 500;

      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }

    &:hover {
      div {
        background: var(--secondary-color);
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-color);

  svg {
    width: 1rem;
    height: auto;
    min-width: 20px;
    color: var(--white-color);
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 125;

    padding: 1rem;

    width: 60%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    background: url("../../assets/bgWhite.png");
    background-color: white;
    background-size: cover;
    background-repeat: repeat;

    /* top box shadow */
    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.85);

    svg {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.5rem 1rem;
      z-index: 25;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      z-index: 125;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      li {
        margin: 1rem;
        z-index: 125;
        a {
          color: var(--gray-color);
          text-decoration: none;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 500;
          margin-bottom: 0.5rem;
          padding: 0.5rem;
          z-index: 125;
          border-radius: 5px;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--secondary-color);
            background-color: azure;
            font-size: 1.25rem;
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}

.icon__container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.icon {
  width: 15px;
  height: auto;
  margin-left: 5px;
  vertical-align: middle; /* Add this line to align the image vertically */
}

.icon__container span {
  display: inline-block; /* Ensure the span element is inline-block */
}

.HiX {
  min-width: 20px;
}

.dark-mode-switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 24px;
  margin-left: 1rem;
  border: 5px;
  p {
    width: 120%;
    font-size: 0.7rem;
    margin-top: 1.55rem;
  }
}

.dark-mode-switch input {
  display: none;
}

.dark-mode-switch label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--secondary-color);
  border-radius: 17px;
  transition: background-color 0.4s;
}

.dark-mode-switch label:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 0px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.4s;
}

.dark-mode-switch input:checked + label {
  background-color: var(--gray-color); /* Green color */
}

.dark-mode-switch input:checked + label:after {
  transform: translateX(26px);
}
