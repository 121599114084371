@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jura&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rubik+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

:root {
  --font-base: "DM Sans", sans-serif;
  --font-header1: "Do Hyeon, sans-serif", sans-serif;
  --font-header2: "Jura", sans-serif;
  --font-header3: "Josefin Slab", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #6f9cc9;
  --black-color: #030303;
  --lightGray-color: #fec5b2;
  --gray-color: #6b7688;
  --brown-color: #ccbc9f;
  --white-color: #ffffffa0;
  --clr-1: #0040ffea;
  --clr-2: #ffffffb5;
  --clr-3: #ffffffa8;
  --clr-4: #ffffff;
  --clr-5: #f90404d6;
  --clr-6: #85a7c961;
  --clr-7: #edf2f8;
  --clr-8: #6f9cc925;
  --clr-9: #edf2f8;
  --clr-10: #85a7c961;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
